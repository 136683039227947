* {
  box-sizing: border-box;
}
#root {
  position: fixed;
  width: 100%;
  height: 100%;
}
.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

h3 {
  background-color: white;
  margin: 10px;
  text-align: center;
}

.try-on-item-button {
  height: 32px;
  width: 80px;
  padding: 10px 8px;
  margin: 0 15px;
  background-color: black;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: grey;
  border: 0px solid black;
}

/* .center .slick-center .try-on-item-button {
  color: white;
  transform: scale(1.2);
} */
.slick-current .try-on-item-button {
  color: white;
  transform: scale(1.2);
}

.iconImage {
  height: 64px;
  width: 64px;
  border-radius: 64px;
}

#icon-slider-div .slick-list {
  background-color: transparent;
  overflow: visible;
  overflow-x: clip;
}
#icon-slider-div .slick-slider {
  width: 70%;

  margin: 40px auto;
}

/* padding between icon image of slider */
#icon-slider-div .slick-initialized .slick-slide {
  display: block;
  padding: 0px 24px;
}
/* padding between catergory name of slider */
#option-slider-div .slick-initialized .slick-slide {
  display: block;
  /* padding: 0px 10px; */
}

#option-slider-div .slick-slider {
  background-color: black;
  height: 64px;
  overflow: hidden;
}

#option-slider-div .slick-track {
  padding-top: 10px;
  padding-bottom: 10px;

  /*wihout center mode*/
  margin-left: 50%;
  /*wihout center mode*/
  display: inline-block;
}

#try-on-slider h3 {
  background-color: rebeccapurple;
  height: 30px;
  width: 100px;
}
#sdk-iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 100;
  bottom: 100;
  border: 0;
  /* z-index: -1; */
}
#option-slider-div {
  position: relative;
}
#option-slider-div .dot {
  position: absolute;
  /* left: 50%; */
  left: calc(50% + 50px);
  top: -55%;
  font-size: 30px;

  text-align: center;
  color: #ebaa8e;
}
#icon-slider-div {
  position: relative;
}
#icon-slider-div .icon-blur-background {
  position: absolute;
  backdrop-filter: blur(25px);
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  bottom: -30%;
  width: 70%;
  left: 15%;
  right: 15%;
  height: 103px;
}
.icon-center-dash {
  color: green;
  position: absolute;
  left: calc(50% + 37px);
  bottom: -10%;
  height: 2px;
  width: 40px;
  z-index: 1;
  @media (max-width: 768px) {
    left: calc(50% + 25px);
    bottom: -25%;
  }
}
/*wihout center mode*/
#icon-slider-div .slick-track {
  margin-left: 50%;
}
/*wihout center mode*/

.icon-center-camera-div {
  width: 64px;
  height: 64px;
  border-radius: 64px;

  position: absolute;
  left: calc(50% + 23px);
  bottom: 0%;
  z-index: 1;
}
.icon-center-camera-background {
  height: 64px;
  width: 64px;
  border-radius: 64px;
  background-color: rgba(86, 83, 83, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
}

#icon-slider-div .icon-center-camera {
  width: 26.67px;
  height: 22.73px;
}

@media only screen and (max-width: 768px) {
  #icon-slider-div .icon-blur-background {
    /* filter: blur(8px); */
    position: absolute;
    border-radius: 0;
    bottom: -80%;
    width: 100%;
    left: 0%;
    right: 0%;
    height: 103px;
  }

  #icon-slider-div .slick-slider {
    width: 100%;
    margin: 40px auto;
  }
}
